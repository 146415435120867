<template>
	<div>
		<div>
			<Header></Header>
		</div>
		<div id="admindiv1">
			<div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item style="font-size: 13px">操作室</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>

		<!--开启摄像头的弹窗-->
		<!--<div class="info2" @click="onTake">-->
		<!--    <el-image :src="url" style="width: 100%; height: 100%"></el-image>-->
		<!--</div>-->

		<!--开启摄像头的拍照和-->
		<el-dialog title="拍照上传" v-model="visible" @close="onCancel1" width="1065px">
			<div class="box">
				<video id="videoCamera" class="canvas" :width="videoWidth" :height="videoHeight" autoPlay></video>
				<canvas id="canvasCamera" class="canvas" :width="videoWidth" :height="videoHeight"></canvas>
			</div>
			<div slot="footer">
				<el-button @click="drawImage" icon="el-icon-camera" size="small">
					拍照
				</el-button>
				<el-button v-if="os" @click="getCompetence" icon="el-icon-video-camera" size="small">
					打开摄像头
				</el-button>
				<el-button v-else @click="stopNavigator" icon="el-icon-switch-button" size="small">
					关闭摄像头
				</el-button>
				<el-button @click="resetCanvas" icon="el-icon-refresh" size="small">
					重置
				</el-button>
				<el-button @click="onCancel" icon="el-icon-circle-close" size="small">
					完成
				</el-button>
			</div>
		</el-dialog>
		<!--    基础信息录入-->
		<div id="forms_div2" style="text-align: center;padding-top: 3%">
			<!--    操作室提示-->
			<div>
				<el-tag style="height: 10vh;font-size: 2rem;width: 50%;line-height: 10vh;" type="danger">{{
						room_name
					}}
				</el-tag>
			</div>
			<!--    上班、下班-->
			<div>
				<el-radio-group v-model="flag" class="ml-4">
					<el-radio label="1" size="large">开始操作打卡</el-radio>
					<el-radio label="2" size="large">结束操作打卡</el-radio>
				</el-radio-group>
			</div>
			<!--    开始操作打卡-->
			<div v-if="flag === '1'">
				<!--    试件-->
				<div style="margin-top: 2%">
					<el-tag style="width: 25%;height: 3.4vh;font-size: 1rem;line-height: 3.4vh;">试件名称</el-tag>
					<el-select v-model="material" class="m-2" placeholder="请选择对应试件名称" size="large" style="width: 25%;" @change="get_message">
						<el-option
							v-for="item in materials"
							:key="item.sysMaterial"
							:label="item.sysMaterial"
							:value="item.sysMaterial"
						/>
					</el-select>
				</div>
				<!--    工作内容录入-->
				<div style="margin-top: 2%">
					<el-tag style="width: 25%;height: 3.4vh;font-size: 1rem;line-height: 3.4vh;">操作内容</el-tag>
					<el-select v-model="message" class="m-2" placeholder="请选择对应操作内容" size="large" style="width: 25%;">
						<el-option
							v-for="item in messages"
							:key="item.workMessage"
							:label="item.workMessage"
							:value="item.workMessage"
						/>
					</el-select>
				</div>
				<!--    姓名录入-->
				<div style="margin-top: 2%">
					<el-tag style="width: 25%;height: 3.4vh;font-size: 1rem;line-height: 3.4vh;">操作人员姓名</el-tag>
					<el-select v-model="user" class="m-2" placeholder="请选择对应操作人员" size="large" style="width: 25%;">
						<el-option
							v-for="item in users"
							:key="item.userName"
							:label="item.userName"
							:value="item.userName"
						/>
					</el-select>
				</div>
				<!--    拍照-->
				<div
					style="margin-top: 2%;margin-bottom: 2%;display: flex;margin-left: 25%;width: 50%;justify-content: space-around">
					<div @click="onTake"
					     style="width: 50%;height: 300px;background-color: #0c89e5;line-height: 300px;font-size: 2rem;color: #FFFFFF">
						拍照
					</div>
					<div v-if="imgSrc !== undefined" style="width: 40%;">
						<img style="height: 300px" :src="imgSrc" alt="#"/>
					</div>
				</div>

				<!--    提交-->
				<div style="margin-top: 2%">
					<el-button style="width: 50%" @click="clock_work" type="danger">开始操作打卡</el-button>
				</div>
			</div>
			<!--    结束操作打卡-->
			<div v-if="flag === '2'">
				<!--    姓名录入-->
				<div style="margin-top: 2%">
					<el-tag style="width: 25%;height: 3.4vh;font-size: 1rem;line-height: 3.4vh;">操作人员姓名</el-tag>
					<el-select v-model="finish_user" class="m-2" placeholder="请选择对应操作人员" size="large"
					           style="width: 25%;">
						<el-option
							v-for="item in users"
							:key="item.userName"
							:label="item.userName"
							:value="item.userName"
						/>
					</el-select>
				</div>

				<!--    提交-->
				<div style="margin-top: 2%">
					<el-button style="width: 50%" @click="finish_work" type="danger">结束操作打卡</el-button>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {setNowTimesInHole} from "@/utils/time";
import Header from "@/views/header.vue";

export default {
	name: "sys_work",
	components: {Header},
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER']),
		...mapGetters(['GET_USERID'])
	},
	//对参数进行设置
	data() {
		return {
			url: "https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/equiment_manage.png",// 上传的图片的地址
			visible: false, //弹窗
			videoWidth: 500,// 绘画布的宽高
			videoHeight: 400,
			os: false, //控制摄像头开关
			thisCancas: null,
			thisContext: null,
			thisVideo: null,
			imgSrc: undefined,
			imgFile: null,
			room_id: '',
			room_name: '',
			users: [],
			messages: [],
			user: '',
			message: '',
			flag: '1',
			finish_user: '',
			materials: [],
			material: ''
		}
	},
	created() {
		//this.onTake()
		const url = window.location.href;
		const cs = url.split('?')[1];
		const jtcs = cs.split("=");
		this.room_id = jtcs[1]
		this.get_material_name()
		this.get_room_name()
		this.get_user_name()
	},
	methods: {
		// 根据room_id 获取操作室名称
		get_room_name() {
			this.axios.get('/sysRoom/getRoomName', (response) => {
				this.room_name = response.obj
			}, {
				room_id: this.room_id
			})
		},
		// 根据room_id 获取试件名称
		get_material_name() {
			this.axios.get('/sysMaterial/getMaterialByRoomId', (response) => {
				this.materials = response.obj
			}, {
				id: this.room_id
			})
		},
		// 根据room_id 查询对应人员
		get_user_name() {
			this.axios.get('/sysUserRoom/getUserByRoom', (response) => {
				this.users = response.obj
			}, {
				room_id: this.room_id
			})
		},
		// 根据room_id 查询对应工作内容
		get_message() {
			this.axios.get('/sysMessage/getMessageByRoom', (response) => {
				this.messages = response.obj
			}, {
				room_id: this.room_id,
				material: this.material
			})
		},
		clock_work() {
			if (this.imgSrc === undefined) {
				ElMessage.error('请拍照!')
			}
			if (this.user === '') {
				ElMessage.error('请选择打卡人!')
			}
			if (this.message === '') {
				ElMessage.error('请选择操作内容!')
			}
			if (this.message !== '' && this.user !== '' && this.imgSrc !== undefined) {
				console.log(this.imgFile)
				this.axios.post('https://www.cr12cz.cn/czscsms/up/upload', (response) => {
					console.log(response)
					this.axios.post('/sysWork/clockWork', (response1) => {
						if (response1.obj) {
							this.reload()
							ElMessage({
								message: '打卡成功!',
								type: 'success'
							})
							this.axios.get('https://www.cr12cz.cn/czscsms/websocketTest/test', (response2) => {

							}, {
								uuid: "233",
								message: "1"
							})
						} else {
							ElMessage.error('打卡失败，请联系科信部!')
						}
					}, {
						user_name: this.user,
						room_name: this.room_name,
						message: this.message,
						time: setNowTimesInHole(new Date()),
						image_src: response.obj,
						material_name: this.material
					})
				}, {
					file: this.imgFile,
					userid: this.GET_USERID
				})

			}
		},
		finish_work() {
			if (this.finish_user === '') {
				ElMessage.error('请选择打卡人!')
			} else {
				this.axios.post('/sysWork/finishWork', (response) => {
					if (response.obj) {
						this.reload()
						ElMessage({
							message: '结束操作打卡成功!',
							type: 'success'
						})
						this.axios.get('https://www.cr12cz.cn/czscsms/websocketTest/test', (response1) => {

						}, {
							uuid: "233",
							message: "1"
						})
					} else {
						ElMessage.error('结束操作打卡失败，请联系科信部!')
					}
				}, {
					user_name: this.finish_user,
					time: setNowTimesInHole(new Date())
				})
			}

		},


		/*调用摄像头拍照开始*/
		onTake() {
			this.visible = true;
			this.getCompetence();
		},
		/*关闭弹窗，以及关闭摄像头功能*/
		onCancel1() {
			this.visible = false;
			this.stopNavigator(); // 关闭摄像头
		},

// 调用摄像头权限
		getCompetence() {
			//必须在model中render后才可获取到dom节点,直接获取无法获取到model中的dom节点
			this.$nextTick(() => {
				const _this = this;
				this.os = false; //切换成关闭摄像头
				// 获取画布节点
				this.thisCancas = document.getElementById("canvasCamera");
				// 为画布指定绘画为2d类型
				this.thisContext = this.thisCancas.getContext("2d");
				//获取video节点
				this.thisVideo = document.getElementById("videoCamera");
				// 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
				if (navigator.mediaDevices === undefined) {
					navigator.menavigatordiaDevices = {};
				}
				// 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
				// 使用getUserMedia，因为它会覆盖现有的属性。
				// 这里，如果缺少getUserMedia属性，就添加它。
				if (navigator.mediaDevices.getUserMedia === undefined) {
					navigator.mediaDevices.getUserMedia = function (constraints) {
						// 首先获取现存的getUserMedia(如果存在)
						let getUserMedia =
							navigator.webkitGetUserMedia ||
							navigator.mozGetUserMedia ||
							navigator.getUserMedia;
						// 有些浏览器不支持，会返回错误信息
						// 保持接口一致
						if (!getUserMedia) {
							return Promise.reject(
								new Error("getUserMedia is not implemented in this browser")
							);
						}
						// 否则，使用Promise将调用包装到旧的navigator.getUserMedia
						return new Promise(function (resolve, reject) {
							getUserMedia.call(navigator, constraints, resolve, reject);
						});
					};
				}
				const constraints = {
					audio: false,
					video: {
						width: _this.videoWidth,
						height: _this.videoHeight,
						transform: "scaleX(-1)"
					}
				};
				navigator.mediaDevices
					.getUserMedia(constraints)
					.then(function (stream) {
						// 旧的浏览器可能没有srcObject
						if ("srcObject" in _this.thisVideo) {
							_this.thisVideo.srcObject = stream;
						} else {
							// 避免在新的浏览器中使用它，因为它正在被弃用。
							_this.thisVideo.src = window.URL.createObjectURL(stream);
						}
						_this.thisVideo.onloadedmetadata = function (e) {
							_this.thisVideo.play();
						};
					})
					.catch(err => {
						this.$notify({
							title: "警告",
							message: "没有开启摄像头权限或浏览器版本不兼容.",
							type: "warning"
						});
					});
			});
		},

		//调用摄像头 --- 进行绘制图片
		drawImage() {
			// 点击，canvas画图
			this.thisContext.drawImage(
				this.thisVideo,
				0,
				0,
				this.videoWidth,
				this.videoHeight
			);
			// 获取图片base64链接
			this.imgSrc = this.thisCancas.toDataURL("image/png");
			console.log(this.imgSrc)

		},
		//清空画布
		clearCanvas(id) {
			let c = document.getElementById(id);
			let cxt = c.getContext("2d");
			cxt.clearRect(0, 0, c.width, c.height);
		},

		//重置画布
		resetCanvas() {
			// this.imgSrc = "";
			this.clearCanvas("canvasCamera");
		},

		//关闭摄像头
		stopNavigator() {
			if (this.thisVideo && this.thisVideo !== null) {
				this.thisVideo.srcObject.getTracks()[0].stop();
				this.os = true; //切换成打开摄像头
			}
		},
		/*调用摄像头拍照结束*/

		/*完成拍照并对其照片进行上传*/
		onCancel() {
			this.visible = false;
			/* this.resetCanvas();*/
			// console.log(this.imgSrc);
			this.imgFile = this.dataURLtoFile(this.imgSrc, setNowTimesInHole(new Date()) + ".png");
			console.log(this.imgFile);
			this.stopNavigator();

		},

		dataURLtoFile(dataurl, filename) {
			var arr = dataurl.split(",");
			var mime = arr[0].match(/:(.*?);/)[1];
			var bstr = atob(arr[1]);
			var n = bstr.length;
			var u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new File([u8arr], filename, {type: mime});
		},
	}
}
</script>

<style>
.info2 {
	width: 10%;
	height: 100px;
}

.box {
	display: flex;
}

#admindiv1 {
	border-radius: 5px;
	background-color: white;
	display: flex;
	width: 95%;
	height: 40px;
	margin: 10px auto 30px;
	justify-content: space-between;
}

#forms_div2 {
	border-radius: 5px;
	margin: 0 auto;
	background-color: white;
	width: 95%;
	padding-top: 20px;
	padding-bottom: 20px;
	min-height: 500px;
}
</style>